<template>
	<div class="intergations-settings">
		<h2>OwlLab Einstellungen</h2>
		<p>
			<small
				>Doppelklick auf einen Wert, um ihn zu bearbeiten. <br />
				Zum Übernehmen der Änderungen bitte loggen Sie sich aus und erneut
				ein.</small
			>
		</p>
		<form @submit.prevent="updateSettings">
			<table>
				<tr>
					<td>URL:</td>
					<td>
						<input
							type="text"
							v-model="$v.settings.url.$model"
							:readonly="!editable.includes('url')"
							@dblclick="editable.push('url')"
							placeholder="localhost"
						/>
						<small
							class="has-error"
							v-if="$v.settings.url.$error && !$v.settings.url.required"
							>OwlLab URL darf nicht leer sein!</small
						>
					</td>
				</tr>
				<tr>
					<td>Port:</td>
					<td>
						<input
							type="number"
							v-model="$v.settings.port.$model"
							:readonly="!editable.includes('port')"
							@dblclick="editable.push('port')"
							placeholder="1234"
						/>
						<small
							class="has-error"
							v-if="$v.settings.port.$error && !$v.settings.port.required"
							>OwlLab Port darf nicht leer sein!</small
						>
					</td>
				</tr>
			</table>

			<button type="submit" class="button w-button" :disabled="!changed">
				Speichern
			</button>
		</form>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
export default {
	data() {
		return {
			settings: {
				url: null,
				port: null
			},
			editable: [],
			changed: false
		};
	},
	watch: {
		settings: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal !== null) {
					this.changed = true;
				} else {
					this.changed = false;
				}
			}
		}
	},
	mounted() {
		this.settings.url = this.$store.getters.getOwlLab.settings.url;
		this.settings.port = this.$store.getters.getOwlLab.settings.port;
	},
	validations: {
		settings: {
			url: {
				required
			},
			port: {
				required
			}
		}
	},
	methods: {
		async updateSettings() {
			this.$v.$touch();
			if (!this.$v.$anyError) {
				await this.$api.post(
					'/lab/update_owllab_settings',
					{
						url: this.settings.url,
						port: this.settings.port
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				this.$store.dispatch('setOwlLabIntegration', {
					active: 1,
					url: this.settings.url,
					port: this.settings.port
				});
				this.clearFields();
			}
		},
		clearFields() {
			this.editable = [];
			this.changed = false;
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
.intergations-settings {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr auto;
	box-shadow: 0 0 20px -12px #394150;
	padding: 3rem;
	background-color: $background-color;
	justify-content: center;
	align-items: center;
	justify-items: center;
	form {
		width: 50%;
		padding: 1.5rem 0;
		input {
			margin: 0.5rem 0;
			text-align: center;
			&:read-only {
				background: none;
				border: none;
				box-shadow: none;
			}
			border-radius: 0;
		}
	}
}
</style>
